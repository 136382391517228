import { Swiper, SwiperOptions, Navigation, Lazy, Controller} from 'swiper';

Swiper.use([Navigation, Lazy, Controller]);



export default function(){

  return {

    swiperBig : null,
    swiperPreview: null,

    initProductDetail: async function($refs){
      const { Swiper, Navigation, Lazy } = await import('swiper');
      Swiper.use([Navigation, Lazy]);
      // @ts-ignore
      this.swiperBig = new Swiper($refs.big, {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        preloadImages: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        lazy: {
          loadPrevNext: true,
          // amount of images to load
          loadPrevNextAmount: 6
        },
        navigation: {
          nextEl: $refs.bigNext,
          prevEl: $refs.bigPrev,
        }
      });

      // @ts-ignore
      this.swiperPreview = new Swiper($refs.preview, {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 5,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
        },
        centerInsufficientSlides: false,
        slideToClickedSlide: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: $refs.previewNext,
          prevEl: $refs.previewPrev,
        },
        breakpoints: {
          576: {
            direction: 'vertical',
          },
          768: {
            direction: 'horizontal',
          },
          992: {
            direction: 'vertical',
          }
        },
        controller: {
          // @ts-ignore
          control: this.swiperBig
        }

      });

      let slides = document.querySelectorAll('.swiper--productImageBig .swiper-wrapper .swiper-slide').length;
      if(slides == 1) {
        // @ts-ignore
        document.querySelector('.swiper--productImagePreview').style.display = "none";
      }
    },
    switchImage(index) {
      // @ts-ignore
      this.swiperBig.slideTo(index);
      // @ts-ignore
      this.swiperPreview.slideTo(index);
    }
  }
}
