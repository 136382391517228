function $w(string) {
    if (!(typeof string === 'string' || string instanceof String)) return [];
    string = string.trim();
    return string ? string.split(/\s+/) : [];
}

function camelize(text, separator = '_') {

    // Assume separator is _ if no one has been provided.
    if(typeof(separator) == "undefined") {
        separator = "_";
    }

    // Cut the string into words
    var words = text.split(separator);

    // Concatenate all capitalized words to get camelized string
    var result = "";
    for (var i = 0 ; i < words.length ; i++) {
        var word = words[i];
        var capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        result += capitalizedWord;
    }

    return result;

}

import Validator from "./validation/validator";
import Translator from "./translate";

class Validation {
    private static methods = {};
    private form: any;
    private isOnChange: boolean;
    private defaultOptions = {
        onSubmit: true,
        stopOnFirst: false,
        immediate: false,
        focusOnError: true,
        useTitles: false,
        addClassNameToContainer: false,
        containerClassName: '.input-box',
        onFormValidate(result, form) {
        },
        onElementValidate(result, elm) {
        }
    };
    private options: any;

    constructor(form: HTMLFormElement, options = {}) {
        this.form = form;
        if (!this.form) {
            return;
        }
        this.options = Object.assign({
            onSubmit: this.defaultOptions.onSubmit,
            stopOnFirst: this.defaultOptions.stopOnFirst,
            immediate: this.defaultOptions.immediate,
            focusOnError: this.defaultOptions.focusOnError,
            useTitles: this.defaultOptions.useTitles,
            onFormValidate: this.defaultOptions.onFormValidate,
            onElementValidate: this.defaultOptions.onElementValidate
        }, options || {});
        if (this.options.onSubmit) {
            this.form.addEventListener('submit', this.onSubmit.bind(this));
        }
        if (this.options.immediate) {
            const that = this;
            [...this.form.elements].forEach(function (input) {
                if (input.tagName.toLowerCase() == 'select') {
                    input.addEventListener('blur', that.onChange.bind(that));
                }
                if (input.type.toLowerCase() == 'radio' || input.type.toLowerCase() == 'checkbox') {
                    input.addEventListener('click', that.onChange.bind(that));
                } else {
                    input.addEventListener('change', that.onChange.bind(that));
                }
            }.bind(this));
        }
    }

    onChange(ev: Event) {
        this.isOnChange = true;
        this.validateElement(ev.target as HTMLElement, {
            useTitle: this.options.useTitles,
            onElementValidate: this.options.onElementValidate
        });
        this.isOnChange = false;
    }

    onSubmit(ev: Event) {
        if (!this.validate()) {
            ev.stopPropagation();
            ev.preventDefault();
            return false;
        }
    }

    validate() {
        var result = true;
        var useTitles = this.options.useTitles;
        var callback = this.options.onElementValidate;
        try {
            const that = this;
            [...this.form.elements].forEach(function (elm) {
                if (elm.classList.contains('local-validation') && !that.isElementInForm(elm, that.form)) {
                    return;
                }
                result = that.validateElement(elm, {useTitle: useTitles, onElementValidate: callback}) && result;
            });
        } catch (e) {
            console.log(e);
        }
        if (!result && this.options.focusOnError) {
            try {
                const elements = this.form.elements.filter(function (elm) {
                    return elm.classList.contains('validation-failed')
                });
                if (elements.length > 0) {
                    elements[0].focus = true;
                }

            } catch (e) {
            }
        }
        this.options.onFormValidate(result, this.form);
        return result;
    }

    reset() {
        const that = this;
        [...this.form.elements].forEach(function (element) {
            that.validateElement(element, {});
        })
    }

    isElementInForm(elm, form) {
        var domForm = elm.closet('form');
        if (domForm == form) {
            return true;
        }
        return false;
    }

    validateElement(elm, options) {
        options = Object.assign({
            useTitle: false,
            onElementValidate(result, elm) {
            }
        }, options || {});

        var cn = $w(elm.className);
        if (cn.length === 0) {
            return true;
        }
        var that = this;
        return cn.every(function (value) {
            var test = that.test(value, elm, options.useTitle);
            options.onElementValidate(test, elm);
            return test;
        });
    }

    insertAdvice(elm, advice) {
        var p = elm.parentNode;
        if (p) {
            p.append(advice)
        }
    }

    showAdvice(elm, advice, adviceName) {
        if (!elm.advices) {
            elm.advices = {};
        } else {
            const that = this;
            Object.values(elm.advices).forEach(function (pair) {
                if (!advice || pair.id != advice.id) {
                    // hide non-current advice after delay
                    that.hideAdvice(elm, pair.value);
                }
            }.bind(this));
        }
        elm.advices[adviceName] = advice;
        advice.style.display = 'block';
    }

    hideAdvice(elm, advice) {
        if (advice != null) {
            advice.style.display = 'none';
        }
    }

    updateCallback(elm, status) {
        if (typeof elm.callbackFunction != 'undefined') {
            eval(elm.callbackFunction + '(\'' + elm.id + '\',\'' + status + '\')');
        }
    }

    allowContainerClassName(elm) {
        if (elm.type == 'radio' || elm.type == 'checkbox') {
            return elm.classList.contains('change-container-classname');
        }

        return true;
    }

    test(name, elm, useTitle) {
        var v = Validation.get(name);
        if (!v) {
            return true;
        }
        var prop = '__advice' + camelize(name);
        try {
            if (this.isVisible(elm) && !v.test(elm.value, elm)) {
                //if(!elm[prop]) {
                var advice = this.getAdvice(name, elm);
                if (advice == null) {
                    advice = this.createAdvice(name, elm, useTitle);
                }
                this.showAdvice(elm, advice, name);
                this.updateCallback(elm, 'failed');
                //}
                elm[prop] = 1;
                if (!elm.advaiceContainer) {
                    elm.classList.remove('validation-passed');
                    elm.classList.add('validation-failed');
                }

                if (this.defaultOptions.addClassNameToContainer && this.defaultOptions.containerClassName != '') {
                    const container = elm.closest(this.defaultOptions.containerClassName);
                    if (container && this.allowContainerClassName(elm)) {
                        container.classList.remove('validation-passed');
                        container.classList.add('validation-error');
                    }
                }
                return false;
            } else {
                const advice = this.getAdvice(name, elm);
                this.hideAdvice(elm, advice);
                this.updateCallback(elm, 'passed');
                elm[prop] = '';
                elm.classList.remove('validation-failed');
                elm.classList.add('validation-passed');
                if (this.defaultOptions.addClassNameToContainer && this.defaultOptions.containerClassName != '') {
                    var container = elm.up(this.defaultOptions.containerClassName);
                    if (container && !container.down('.validation-failed') && this.allowContainerClassName(elm)) {
                        if (!Validation.get('IsEmpty').test(elm.value) || !this.isVisible(elm)) {
                            container.classList.add('validation-passed');
                        } else {
                            container.classList.remove('validation-passed');
                        }
                        container.classList.remove('validation-error');
                    }
                }
                return true;
            }
        } catch (e) {
            throw(e)
        }
    }
    isVisible(elm) {
        while (elm.tagName != 'BODY') {
            if (!!( elm.offsetWidth || elm.offsetHeight || elm.getClientRects().length )) {
                elm = elm.parentNode;
            } else {
                return false;
            }
        }
        return true;
    }
    getAdvice(name, elm) {
        return document.getElementById('advice-' + name + '-' + this.getElmID(elm)) || document.getElementById('advice-' + this.getElmID(elm));
    }
    createAdvice(name, elm, useTitle, customError = null) {
        var v = Validation.get(name);
        var errorMsg = useTitle ? ((elm && elm.title) ? elm.title : v.error) : v.error;
        if (customError) {
            errorMsg = customError;
        }
        errorMsg = Translator.translate(errorMsg);

        var advice = '<div class="validation-advice" id="advice-' + name + '-' + this.getElmID(elm) + '" style="display:none">' + errorMsg + '</div>'

        var div = document.createElement('div');
        div.innerHTML = advice.trim();
        this.insertAdvice(elm, div.firstChild);
        return this.getAdvice(name, elm);
    }
    getElmID(elm) {
        return elm.id ? elm.id : elm.name;
    }
    resetElement(elm) {
        var cn = $w(elm.className);
        var that = this;
        cn.each(function (value) {
            var prop = '__advice' + camelize(value);
            if (elm[prop]) {
                var advice = that.getAdvice(value, elm);
                if (advice) {
                    advice.style.display = 'none';
                }
                elm[prop] = '';
            }
            elm.classList.remove('validation-failed');
            elm.classList.remove('validation-passed');
            if (that.defaultOptions.addClassNameToContainer && that.defaultOptions.containerClassName != '') {
                var container = elm.up(that.defaultOptions.containerClassName);
                if (container) {
                    container.classList.remove('validation-passed');
                    container.classList.remove('validation-error');
                }
            }
        });
    }
    public static add(className, error, test, options = {}) {
        Validation.methods[className] = new Validator(className, error, test, options);
    }

    public static addAllThese(validators) {
        var nv = {};
        validators.forEach(function (value) {
            Validation.add(value[0], value[1], value[2], (value.length > 3 ? value[3] : {}));
        });
    }
    public static get(name) {
        return Validation.methods[name] ? Validation.methods[name] : Validation.methods['_LikeNoIDIEverSaw_'];
    }

}

window['Validation'] = Validation;

export default Validation;
