export function getCookie(name):string|null {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export function setCookie(name, value, days = 0) {
    let expires = '';

    if (days != 0) {
        const d = new Date;
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        expires = ";expires=" + d.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value) + ";path=/" + expires;
}

export function deleteCookie(name) {
    console.log('deleteing cookie', name);
    setCookie(name, '', -1);
}
