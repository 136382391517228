document.addEventListener('click', function(event) {
    if (event.target) {
        let target = event.target as HTMLElement;
        const selector = 'a[data-post]';
        if (!target.matches(selector + ', ' + selector + ' *')) {
            return;
        }
        if (!target.closest(selector)) {
            return;
        }

        target = target.closest(selector) as HTMLElement;

        if (!target.dataset.post) {
            return;
        }
        const data = JSON.parse(target.dataset.post);
        if ((!data.url && !data.action) || !data.data) {
            return;
        }
        const form = document.createElement('form');
        form.classList.add('d-none');
        form.method = 'POST';
        form.action = data?.url ? data.url : data.action;

        data.data['form_key'] = window['FORM_KEY'];
        for (const key in data.data) {
            if (data.data.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = data.data[key];

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();

    }
}, false);
