import {getCookie, setCookie } from "@frontend/utilities/cookie";

const inputSelector = 'input[name="form_key"]',
    allowedCharacters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    length = 16;

function generateRandomString(chars, length) {
    var result = '';
    length = length > 0 ? length : 1;
    while (length--) {
        result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
}

var formKey = getCookie('form_key');
if (!formKey) {
    formKey = generateRandomString(allowedCharacters, length);
    setCookie('form_key', formKey);
}


function initForms(formKey: string) {
    const inputs = document.querySelectorAll(inputSelector);
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        if (input instanceof HTMLInputElement) {
            input.value = formKey;
        }
    }

}

initForms(formKey);

window.addEventListener('load', function () {
    if (formKey) {
        initForms(formKey);
    }
});

window['FORM_KEY'] = formKey;

export default formKey;

