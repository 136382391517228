class Translate {
    private data = {};

    constructor(data = {}) {
        this.data = data;
    }

    translate(text: string){
        if(this.data[text]){
            return this.data[text];
        }
        return text;
    }

    add(key: string|object, value: string|null = null) {
        if (typeof key === 'string' && value) {
            this.data[key] = value;
        } else if (typeof key =='object') {
            for (const k in key) {
                if (!key.hasOwnProperty(k)) {
                    continue;
                }
                this.data[k] = key[k];
            }
        }
    }
}

const translator = new Translate();
window['Translator'] = translator;
if (window['TRANSLATIONS']) {
    translator.add(window['TRANSLATIONS']);
} else {
    window.addEventListener('load', function () {
        if (window['TRANSLATIONS']) {
            translator.add(window['TRANSLATIONS']);
        }
    });
}

export default translator;


