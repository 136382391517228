
export default function ({id, qty = 1, initalPrice, initialTierPrices = [], customOptionPrices = []}) {
    
    const result: {
        activeProductsPriceData: boolean|any
        activeCustomOptions: any,
        initialFinalPrice: number,
        calculatedFinalPrice: boolean|number,
        calculatedFinalPriceWithCustomOptions: boolean|number,
        initialTierPrices: any[],
        customOptionPrices: any[],
        qty: number,
        updateCustomOptionActive: (any) => void,
        updateCustomOptionPrices: (any) => void,
        formatPrice: (number) => string,
        calculateFinalPrice: () => void,
        calculateFinalPriceWithCustomOptions: () => void,
        getFormattedFinalPrice: () => string,
        eventListeners: any
    } = {
        activeProductsPriceData: false,
        initialFinalPrice: initalPrice,
        calculatedFinalPrice: false,
        calculatedFinalPriceWithCustomOptions: false,
        initialTierPrices: initialTierPrices,
        customOptionPrices: customOptionPrices,
        activeCustomOptions: [],
        qty: qty,
        updateCustomOptionActive(data) {

            let activeCustomOptions = this.activeCustomOptions;
            const customOptionId = data.customOptionId;

            if(data.active) {
                if (!activeCustomOptions.includes(customOptionId)) {
                    activeCustomOptions.push(data.customOptionId);
                }
            } else {
                if (activeCustomOptions.includes(customOptionId)) {
                    let index = activeCustomOptions.indexOf(customOptionId);
                    activeCustomOptions.splice(index, 1);
                }
            }
            this.calculateFinalPriceWithCustomOptions()
        },
        updateCustomOptionPrices(prices) {

            if (prices) {
                this.customOptionPrices = prices;
            }

            this.calculateFinalPriceWithCustomOptions();
        },
        formatPrice(value) {
            var formatter = new Intl.NumberFormat(
                document.documentElement.lang,
                {
                    style: 'currency',
                    currency: 'EUR'
                }
            );
            return (typeof Intl.NumberFormat.prototype.formatToParts) ?
                formatter.formatToParts(value).map(({type, value}) => {
                    switch (type) {
                        case 'currency':
                            return '€' || value;
                        case 'minusSign':
                            return '- ';
                        case 'plusSign':
                            return '+ ';
                        default :
                            return value;
                    }
                }).reduce((string, part) => string + part) :
                formatter.format(value);
        },
        calculateFinalPrice() {
            let finalPrice = this.initialFinalPrice;

            if (this.activeProductsPriceData && this.activeProductsPriceData.tierPrices) {
                finalPrice = this.activeProductsPriceData.tierPrices.reduce((finalValue, tierPrice) => {
                    if (this.qty >= tierPrice.qty) {
                        return tierPrice.price < finalValue ? tierPrice.price : finalValue;
                    }
                    return finalValue;
                }, this.activeProductsPriceData.finalPrice.amount);

            } else {
                finalPrice = Object.values(this.initialTierPrices).reduce((finalValue, tierPrice) => {
                    if (this.qty >= tierPrice.price_qty) {
                        return parseFloat(tierPrice.website_price) < finalValue ?
                            parseFloat(tierPrice.website_price) :
                            finalValue;
                    }
                    return finalValue;
                }, finalPrice);

            }
            this.calculatedFinalPrice = finalPrice;

            window.dispatchEvent(
                new CustomEvent(
                    "update-product-final-price",
                    {detail: this.calculatedFinalPrice}
                )
            );
        },
        calculateFinalPriceWithCustomOptions() {
            let finalPrice = this.calculatedFinalPrice ||
                this.initialFinalPrice;

            this.calculatedFinalPriceWithCustomOptions =
                this.activeCustomOptions.reduce((priceAccumulator, activeCustomOptionId) => {
                    const customOptionPrice = this.customOptionPrices[activeCustomOptionId];
                    if(customOptionPrice) {
                        return Number.parseFloat(priceAccumulator) + Number.parseFloat(customOptionPrice);
                    }
                    return finalPrice;
                }, finalPrice);

        },
        getFormattedFinalPrice() {
            return this.formatPrice(
                this.calculatedFinalPriceWithCustomOptions ||
                this.calculatedFinalPrice ||
                this.initialFinalPrice
            )
        },
        eventListeners: {
            ['@update-prices-'+id+'.window'](event) {
                this.activeProductsPriceData = event.detail;
                this.calculateFinalPrice();
                this.calculateFinalPriceWithCustomOptions();
            },
            ['@update-qty-'+id+'.window'](event) {
                this.qty = event.detail;
                this.calculateFinalPrice();
                this.calculateFinalPriceWithCustomOptions();
            },
            ['@update-custom-option-active.window'](event) {
                this.updateCustomOptionActive(event.detail);
            },
            ['@update-custom-option-prices.window'](event) {
                this.updateCustomOptionPrices(event.detail);
            }
        }
    }

    return result;
}
