
export default function () {
    return {
        init: {
            ['@private-data-loaded.window']: function($event) {
                const data = $event.detail.data;
                if (!data.cart) {
                    return;
                }
                this['wishlist'] = !!data.wishlist;
            }
        },
        wishlist: {
            counter: 0
        }
    }
}

