import init from '@frontend/init';
import Account from '@frontend/components/Account';
import Cart from '@frontend/components/Cart';
import Filters from '@frontend/components/Filters';
import Header from '@frontend/components/Header';
import MegaDropDown from '@frontend/components/MegaDropDown';
import Messages from '@frontend/components/Messages';
import MobileMenu from '@frontend/components/MobileMenu';
import Modal from '@frontend/components/Modal';
import OnSiteSearch from './components/OnSiteSearch';
import SlideProductMedia from '@frontend/components/SlideProductMedia';
import Search from '@frontend/components/Search';
import SearchMobile from '@frontend/components/SearchMobile';
import ShoppingBar from '@frontend/components/ShoppingBar';
import SlideCategories from '@frontend/components/SlideCategories';
import SlideImages from '@frontend/components/SlideImages';
import SlideProducts from '@frontend/components/SlideProducts';
import SlideStage from '@frontend/components/SlideStage';
import Wishlist from '@frontend/components/Wishlist';
import ConfigurableProduct from '@frontend/components/ConfigurableProduct';
import Price from '@frontend/components/Price';
import UpdateQuantity from '@frontend/components/UpdateQuantity';
import RegionData from "@frontend/components/RegionData";
import AddToCart from '@frontend/components/AddToCart';
import AddToCartLightbox from '@frontend/components/AddToCartLightbox';
import MegaMenuTeaser from '@frontend/components/MegaMenuTeaser';

import lazyload from "@frontend/utilities/lazyload";
import { initPrivateData } from "@frontend/utilities/private-data";

import "@frontend/utilities/form-key";

import "@frontend/utilities/lazyload";
import "@frontend/utilities/lightbox";
import "@frontend/utilities/matchHeight";

import "@frontend/utilities/data-post";
import "@frontend/utilities/varienform";
import "@frontend/utilities/validation/standard-validations";

init({
    Account,
    Cart,
    Filters,
    Header,
    MegaDropDown,
    Messages,
    MobileMenu,
    Modal,
    OnSiteSearch,
    SlideProductMedia,
    Search,
    SearchMobile,
    ShoppingBar,
    SlideCategories,
    SlideImages,
    SlideProducts,
    SlideStage,
    Wishlist,
    ConfigurableProduct,
    UpdateQuantity,
    RegionData,
    AddToCart,
    AddToCartLightbox,
    MegaMenuTeaser,
    Price
});

lazyload.update();
initPrivateData();
