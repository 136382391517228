import { createPopper } from '@popperjs/core';
import Tooltip from 'bootstrap/js/src/popover';
import lightbox from '../utilities/lightbox';
const isIOS = /iPhone|iPad|iPod/.test(navigator.platform);

export default function ({optionsConfig, itemId, productId, swatchConfig, dynamicDataUrl}) {
    const result: {
        pendingRequestId: number|null,
        currentRequest: any|null,
        tooltipInstances: any[],
        swatchConfig: any,
        optionConfig: any,
        allowedAttributeOptions: string[]
        selectedValues: {},
        productIndex: number|null,
        itemId: number,
        productId: number
        initConfiguration: ($nextTick, $refs) => void,
        findSimpleIndex: () => void,
        findAllowedAttributeOptions: () => void,
        preSelectOptions: () => void,
        getAllowedAttributeOptions: any,
        changeOption: (optionId, value) => void,
        getImageHtml: (item, swatchConfig) => void,
        updatePrices: () => void,
        updateGallery: () => void,
        updateDynamicContent: () => void,
        onSuccessDynamicContentReceived: (any) => void,
        onErrorDynamicContentReceived: (any) => void,
        onGetCartData: (data) => void,
        closeTooltips: () => void,
        showTooltip: ($el: any, attributeId: string, optionId: string) => void,
        click: ($el: any, attributeId: string, optionId: string) => void,
        hover: ($el: any, attributeId: string, optionId: string) => void
    } = {
        pendingRequestId : null,
        currentRequest: null,
        tooltipInstances: [],
        swatchConfig: swatchConfig || {},
        optionConfig: optionsConfig,
        allowedAttributeOptions: [],
        selectedValues: {},
        productIndex: 0,
        itemId: itemId,
        productId: productId,
        initConfiguration: function ($nextTick) {
            this.findAllowedAttributeOptions();
            $nextTick(() => {
                this.preSelectOptions();
            });
        },
        preSelectOptions: function () {
            const $this = this;
            const allAttributes = this.optionConfig.attributes;
            // @ts-ignore
            const allAttributesSorted: any[] = Object.values(allAttributes).sort(function (a: {position:number},b: {position:number}) {
                return a.position - b.position
            });
            allAttributesSorted.forEach((attribute) => {
                const options = this.getAllowedAttributeOptions(attribute.id);
                if (options.length === 0) {
                    return;
                }
                console.log(options, options[0]);
                this.changeOption(parseInt(attribute.id), options[0].id);
            });

            // pre-select option like #12=167&13=168
            if (window.location.hash) {
                const query = new URLSearchParams(window.location.hash.replace('#',''))
                for (var attributeId of query.keys()) {
                    // @ts-ignore
                    var value = query.get(attributeId);
                    if (!value) {
                        continue;
                    }
                    this.changeOption(parseInt(attributeId), value);
                }
            }
        },
        findSimpleIndex: function findSimpleIndex () {
            const productIndexes = this.optionConfig.index;
            const $this = this;
            const index = Object.keys(productIndexes).find(function(productIndex) {
                var currentProductIndex = productIndexes[productIndex];

                for (var productOption in currentProductIndex) {
                    if (
                        $this.selectedValues[productOption] &&
                        $this.selectedValues[productOption] !== currentProductIndex[productOption]
                    ) {
                        return null;
                    }
                }
                return parseInt(productIndex);
            });
            this.productIndex = index === undefined ? 0 : parseInt(index);
        },
        findAllowedAttributeOptions: function findAllowedAttributeOptions() {
            var $this = this;

            var allAttributes = this.optionConfig.attributes;
            // @ts-ignore
            var allAttributesSorted: any[] = Object.values(allAttributes).sort(function (a: {position:number},b: {position:number}) {
                return a.position - b.position
            });
            var previousOption:Boolean|number = false;
            var productIndexes = this.optionConfig.index;
            var availableIndexes = Object.keys(productIndexes);

            var newAllowedAttributeOptions: string[] = [];

            allAttributesSorted.forEach(function (attribute) {
                if (previousOption && $this.selectedValues[parseInt(previousOption as any)]) {
                    availableIndexes = availableIndexes.filter(function(availableIndex) {
                        return productIndexes[availableIndex][previousOption as number] ===
                            $this.selectedValues[previousOption as number]
                    })
                }
                newAllowedAttributeOptions[attribute.id] =
                    allAttributes[attribute.id].options.filter(function (option) {
                        return !!option.products.find(function (product) {
                            // @ts-ignore
                            return availableIndexes.includes(product);
                        })
                    });
                previousOption = attribute.id;
            });
            this.allowedAttributeOptions = newAllowedAttributeOptions;
        },
        getAllowedAttributeOptions: function getAllowedAttributeOptions(attributeId) {
            return this.allowedAttributeOptions[attributeId] || []
        },
        changeOption: function changeOption (optionId, value) {
            this.selectedValues[""+optionId] = value;
            this.findSimpleIndex();
            this.findAllowedAttributeOptions();
            this.updatePrices();
            this.updateGallery();
            this.updateDynamicContent();
        },
        getImageHtml: function (item, swatchConfig) {

            var imagePath = null;
            // @ts-ignore
            for (const [key, items] of Object.entries(swatchConfig)) {
                // @ts-ignore
                for (const [checkId, _item] of Object.entries(items)) {
                    // @ts-ignore
                    if(checkId == item.id) imagePath =_item.thumb;
                    if(imagePath) break;
                }
                if(imagePath) break;
            }

            return "<img src='" + imagePath + "'><br /><span>" + item.label + "</span>";
        },
        click: function ($el, attributeId, optionId) {
            if (isIOS) {
                this.showTooltip($el, attributeId, optionId);
            }
            this.changeOption(attributeId, optionId);
        },
        hover: function ($el, attributeId, optionId) {
            if (!isIOS) {
                this.showTooltip($el, attributeId, optionId);
            }
        },
        showTooltip: function ($el, attributeId, optionId) {
            if (!this.swatchConfig[attributeId] || !this.swatchConfig[attributeId][optionId]) {
                return;
            }
            this.closeTooltips();
            const value = this.swatchConfig[attributeId][optionId].value;
            const label = this.swatchConfig[attributeId][optionId].label;
            const image = "<img src='" + this.swatchConfig[attributeId][optionId].value + "' />";

            let title = '';
            if (value && this.swatchConfig[attributeId][optionId].thumb) {
                title = `<img src="${value}" alt="${label}" title="${label}" class="img-fluid"/>`;
            } else if(value) {
                title = `<div style="background-color: ${value}; width: 200px; height: 200px" title="${label}"></div>`;
            }

            if(!title) return;

            if (!$el.__tooltipInstance) {
                const tooltip = new Tooltip($el, {
                    html: true,
                    content: title,
                    title: label,
                    sanitize: false,
                    trigger: 'manual',
                    placement: 'top',
                    fallbackPlacements: ['top', 'bottom']
                });
                $el.__tooltipInstance = tooltip;
                this.tooltipInstances.push(tooltip);
            }
            $el.__tooltipInstance.show();
        },
        closeTooltips: function () {
            this.tooltipInstances.forEach((tootltip) => {
                tootltip.hide();
            });
        },
        updatePrices: function updatePrices () {
            var value = this.productIndex ?
                this.optionConfig.optionPrices[this.productIndex] :
                this.optionConfig.prices;
            window.dispatchEvent(
                new CustomEvent(
                    "update-prices-"+this.productId,
                    { detail: value }
                )
            );
        },
        updateDynamicContent: function () {

            if (!this.productIndex || this.pendingRequestId === this.productIndex) {
                return;
            }
            const $this = this;
            this.pendingRequestId = this.productIndex;
            if (this.currentRequest) {
                this.currentRequest.abort();
            }
            this.currentRequest = new XMLHttpRequest();
            this.currentRequest.open('GET', dynamicDataUrl+'?id='+encodeURIComponent(""+this.productIndex));
            this.currentRequest.setRequestHeader('Content-Type', 'application/json');
            this.currentRequest.onload = function() {
                if ($this.currentRequest.status === 200) {
                    const json = JSON.parse($this.currentRequest.responseText);
                    $this.onSuccessDynamicContentReceived.call($this, json);
                }
                else if ($this.currentRequest.status !== 200) {
                    $this.onErrorDynamicContentReceived.call($this, $this.currentRequest);
                }
                $this.currentRequest = null;
            };
            this.currentRequest.send();
        },
        onErrorDynamicContentReceived: function (xhr) {
            console.log(xhr.responseText);
        },
        onSuccessDynamicContentReceived: function (json) {
            json.forEach(element => {
                document.querySelectorAll(element.selector).forEach((elementToReplace) => {
                    var wrapper= document.createElement('div');
                    wrapper.innerHTML= element.content;
                    const div = wrapper.firstChild;
                    elementToReplace.replaceWith(div);
                    lightbox.reload();
                })
            });
        },
        updateGallery: function updateGallery () {
            // @ts-ignore
            var value = this.productIndex ? this.optionConfig.images[this.productIndex] : Object.values(this.optionConfig.images)[0];

            value && window.dispatchEvent(
                new CustomEvent(
                    "update-gallery",
                    { detail: value }
                )
            );
        },

        onGetCartData: function onGetCartData(data) {
            // pre-select options based on cart data for current (quote) itemId
            var cart = data && data.cart;
            if (cart && cart.items) {
                const $this = this;
                var cartItem = cart.items.find(function (item) {
                    return (
                        item.item_id === $this.itemId
                        && item.product_id === $this.productId
                    )
                });
                if (cartItem && cartItem.options && cartItem.options.length) {
                    cartItem.options.map(option => {
                        this.changeOption(option.option_id, option.option_value);
                    })
                }
            }

            // pre-select option like ?size=167
            const urlQueryParams = new URLSearchParams(window.location.search.replace('?',''));
            // @ts-ignore
            Object.values(this.optionConfig.attributes).map((attribute: any) => {
                urlQueryParams.get(attribute.code) &&
                this.changeOption(attribute.id, urlQueryParams.get(attribute.code));
            });

            // pre-select option like #144=167
            const urlHashParams = new URLSearchParams(window.location.hash.replace('#',''));
            // @ts-ignore
            Object.values(this.optionConfig.attributes).map((attribute: any) => {
                urlHashParams.get(attribute.id) &&
                this.changeOption(attribute.id, urlHashParams.get(attribute.id));
            });
        }
    }

    return result;
}
