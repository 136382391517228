import {getCookie, deleteCookie } from "@frontend/utilities/cookie";

export default function () {

    return {
        messages: [],
        init: {
            ['@private-data-loaded.window']: function($event) {
                const data = $event.detail.data;
                if (!data.messages || !data.messages.messages) {
                    return;
                }
                if (this['addMessages']) {
                    this['addMessages'](data.messages.messages, 10000);
                }
            },
            ['@messages-loaded.window']: function($event) {
                const messages = $event.detail.messages;
                if (!messages) {
                    return;
                }
                if (this['addMessages']) {
                    this['addMessages'](messages);
                }
            }
        },
        initMessages: function () {
            const messages = getCookie('mage-messages');
            this.messages = messages ? JSON.parse(decodeURIComponent(messages).replace(/\+/g, ' ')) : [];
            deleteCookie('mage-messages');
        },
        isEmpty: function () {
            return this.messages.reduce(
                function (isEmpty, message) {
                    return isEmpty && message === undefined
                }, true
            )
        },
        removeMessage: function (messageIndex) {
            // (this.messages[messageIndex] as any|null) = null;
            delete this.messages[messageIndex];
        },
        addMessages: function (messages, hideAfter = false) {
            const $this = this;
            messages.map(function(message) {
                $this.messages = $this.messages.concat(message);
                if (hideAfter) {
                    $this.setHideTimeOut($this.messages.length -1, hideAfter);
                }
            });
        },
        getType: function (messageType: string) {
            switch (messageType) {
                case 'error':
                    return 'danger';
                default:
                    return messageType;
            }
        },
        setHideTimeOut: function (messageIndex, hideAfter) {
            const $this = this;
            setTimeout(
                function(messageIndex) {
                    $this.removeMessage(messageIndex);
                },
                hideAfter,
                messageIndex
            );
        }
    }

}
