import Headroom from "headroom.js";

export default function () {
    return {
        initHeader: function($refs, $nextTick){

            const header = $refs.header;
            const pagewrapper = document.querySelector<HTMLElement>(".page-wrapper");

            const options = {
                onTop : function() {
                    //console.log("on top");
                    //document.documentElement.style.setProperty('--page-wrapper-padding-top', Math.round(header.clientHeight) + "px");
                },
                onNotTop : function() {
                    //console.log("on not top");
                    //document.documentElement.style.setProperty('--page-wrapper-padding-top', Math.round(header.clientHeight) + "px");
                }
            };

            //document.documentElement.style.setProperty('--page-wrapper-padding-top', Math.round(header.clientHeight) + "px");
            const headroom = new Headroom(header, options);

            headroom.init();
        },
        onResize: function($refs){

            const header = $refs.header;
            //document.documentElement.style.setProperty('--page-wrapper-padding-top', Math.round(header.clientHeight) + "px");

        }
    }
}
