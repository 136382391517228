import { Swiper, Navigation, Lazy} from 'swiper';
Swiper.use([Navigation, Lazy]);

export default function(data){

    return {
        initSlideProducts: function($refs){
            let el = $refs.swiper;
            let swiper;

            let autoplay;
            let loop; //if autoplay true, enable loop

            if(el.getAttribute('data-autoplay') != 'false'){
                autoplay = { delay: el.getAttribute('data-autoplay') * 1000 };
                loop = true;
            } else {
                autoplay = false;
                loop = false;
            }
            
            if(data === 1){
                swiper = new Swiper(el, {
                    loop: loop,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    preloadImages: false,
                    autoplay: autoplay,
                    lazy: {
                        loadPrevNext: true,
                    },
                    slideToClickedSlide: false,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });

            } else if(data === 2) {
                swiper = new Swiper(el, {
                    loop: loop,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    preloadImages: false,
                    autoplay: autoplay,
                    lazy: {
                        loadPrevNext: true,
                    },
                    slideToClickedSlide: false,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });

            } else if(data === 6) {
                swiper = new Swiper(el, {
                    loop: loop,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    preloadImages: false,
                    autoplay: autoplay,
                    lazy: {
                        loadPrevNext: true,
                    },
                    slideToClickedSlide: false,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    breakpoints: {
                        480: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 4
                        },
                        992: {
                            slidesPerView: 6
                        }
                    },
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });
            } else if(data === 4) {
                swiper = new Swiper(el, {
                    loop: loop,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    preloadImages: false,
                    autoplay: autoplay,
                    lazy: {
                        loadPrevNext: true,
                    },
                    breakpoints: {
                        480: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 3
                        },
                        992: {
                            slidesPerView: 4
                        }
                    },
                    navigation: {
                        nextEl: $refs.next,
                        prevEl: $refs.prev
                    }
                });
            }
        }
    }
}

