import getBrowserStorage from '@frontend/utilities/storage';

const storage = getBrowserStorage();

const IS_EXPANDED_STATE_KEY = '__filters_is_expaned'
const OPEN_FILTER_STATE_KEY = '__filters_open_filter'

export default function (data) {

    const  filters = {};
    for (var i = 0; i < data.filters.length; i++) {
        let filter = data.filters[i];
        filter.search = '';
        if (filter.type == 'slider') {
            filter.sliderState = {
                from: 0,
                max: 0
            }
        }

        Object.defineProperty(filter, 'results', {
            get: function() {
                if (this.search === "") {
                    return this.items;
                }
                return this.items.filter((item) => {
                    return item.label
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                });
            }
        });
        filters[filter.parameter] = filter;
    }

    return {
        isExpanded: false,
        currentOpenFilter: '',
        filters: filters,
        filtersState: {},
        prefix: '',
        onSliderMinMove: function (filterCode: string) {
            let filter = this.filters[filterCode];
            if (!filter) {
                return;
            }
            filter.from = Math.min(parseFloat(filter.to), parseFloat(filter.from));
            filter.from = Math.max(parseFloat(filter.min), parseFloat(filter.from));
            filter.from = Math.min(parseFloat(filter.max), parseFloat(filter.from));
            filter.sliderState.from = ((parseFloat(filter.from) - parseFloat(filter.min)) / (parseFloat(filter.max) - parseFloat(filter.min))) * 100;

        },
        onSliderMaxMove: function (filterCode: string) {
            let filter = this.filters[filterCode];
            if (!filter) {
                return;
            }
            filter.to = Math.max(parseFloat(filter.from), parseFloat(filter.to));
            filter.to = Math.max(parseFloat(filter.min), parseFloat(filter.to));
            filter.to = Math.min(parseFloat(filter.max), parseFloat(filter.to));
            filter.sliderState.to = ((parseFloat(filter.to) - parseFloat(filter.min)) / (parseFloat(filter.max) - parseFloat(filter.min))) * 100;

        },
        initFilters: function(){
            this.prefix = Math.random().toString(36).substring(7);
            if (storage) {
                this.isExpanded = storage.getItem(IS_EXPANDED_STATE_KEY) == '1';
                this.currentOpenFilter = storage.getItem(OPEN_FILTER_STATE_KEY) as string;
            }
            for (var i = 0; i < data.filters.length; i++) {
                let filter = this.filters[data.filters[i].parameter];
                if (filter.type == 'slider') {
                    this.onSliderMinMove(filter.parameter as string);
                    this.onSliderMaxMove(filter.parameter as string);
                }
            }
        },
        openFilter: function (filterCode: string) {
            if (this.currentOpenFilter == filterCode) {
                this.closeFilter(filterCode);
                return;
            }
            if (storage) {
                storage.setItem(OPEN_FILTER_STATE_KEY, filterCode);
            }
            this.currentOpenFilter = filterCode;
        },
        closeFilter: function (filterCode: string) {
            if (this.currentOpenFilter != filterCode) {
                return;
            }
            if (storage) {
                storage.setItem(OPEN_FILTER_STATE_KEY, '');
            }
            this.currentOpenFilter = '';
        },
        expand: function () {
            if (storage) {
                storage.setItem(IS_EXPANDED_STATE_KEY, '1');
            }

            this.isExpanded = true;
        },
        collapse: function () {
            if (storage) {
                storage.setItem(IS_EXPANDED_STATE_KEY, '0');
            }
            this.isExpanded = false;
        },
        generateHtmlItemId: function (filterCode: string, item: any) {
            return 'filter_'+this.prefix+'_'+filterCode+'_'+item.value;
        },
        onFilterItemChange($event, filterCode: string, item: any)
        {
            if (this.filters[filterCode].is_multiselect || !item.is_selected) {
                return;
            }
            // deselect all other options
            for(let i = 0; i < this.filters[filterCode].items.length; i++) {
                const currentItem =  this.filters[filterCode].items[i];
                if (item.value === currentItem.value) {
                    continue;
                }
                currentItem.is_selected = false;
            }

        },
        openMobileFilters: function (element, title) {
            const openFiltersEvent = new CustomEvent("modal-open", {
                detail: {
                    content: element,
                    title: title
                }
            });
            window.dispatchEvent(openFiltersEvent);
        },
        apply: function () {
            const url = data.url == '#' ? new URL(window.location.href) : new URL(data.url);
            url.hash = 'filters';
            for(let requestVar in this.filters) {
                if (!this.filters.hasOwnProperty(requestVar)) {
                    continue;
                }
                url.searchParams.delete(requestVar)
                const selectedItems: string[] = [];
                const filter = this.filters[requestVar];
                if (filter.from !== undefined && filter.to !== undefined) {
                    if (!filter.from && !filter.to) {
                        continue;
                    }
                    if (filter.from == filter.min && filter.to == filter.max) {
                        continue;
                    }
                    const from = parseFloat(filter.from ? filter.from : filter.min);
                    const to =  parseFloat(filter.to ? filter.to : filter.max);
                    selectedItems.push(`${from}-${to}`);
                } else {
                    for (let i = 0; i < filter.items.length; i++) {
                        const item = filter.items[i] as any;
                        if (item.is_selected) {
                            selectedItems.push(item.value as string);
                        }
                    }
                }

                if (selectedItems.length) {
                    url.searchParams.append(requestVar, selectedItems.join(','));
                }
            }

            window.location.href = url.toString();
        }
    }
}
