import Validation from "../validation";

class Validator {
    private options: any;
    private _test: any;
    private error: string;
    private className: string;
    constructor(className: string, error, test, options) {
        if(typeof test == 'function'){
            this.options = options;
            this._test = test;
        } else {
            this.options = test;
            this._test = function(){return true};
        }
        this.error = error || 'Validation failed.';
        this.className = className;
    }
    test(v, elm) {
        return (this._test(v,elm) && Object.values(this.options).every(function(p: any){
            return Validator.methods[p.key] ? Validator.methods[p.key](v,elm,p.value) : true;
        }));
    }
    private static methods = {
        pattern: function (v,elm,opt) {
            return Validation.get('IsEmpty').test(v) || opt.test(v)
        },
        minLength: function (v,elm,opt) {
            return v.length >= opt
        },
        maxLength: function (v,elm,opt) {
            return v.length <= opt
        },
        min: function (v,elm,opt) {
            return v >= parseFloat(opt)
        },
        max: function (v,elm,opt) {
            return v <= parseFloat(opt)
        },
        notOneOf: function (v,elm,opt) {
            const result = opt.forEach(function(value) {
                return v != value;
            });
            result.length === opt.length;
        },
        oneOf: function (v,elm,opt) {
            const result = opt.forEach(function(value) {
                return v == value;
            });
            return result > 0;
        },
        is: function (v,elm,opt) {
            return v == opt
        },
        isNot: function (v,elm,opt) {
            return v != opt
        },
        include: function (v,elm,opt) {
            return opt.forEach(function(value) {
                return Validation.get(value).test(v,elm);
            })
        }
    }

}

export default Validator
