export default function () {
    return {

        isAddToCartAction: false,
        qty: 1,
        disabled: false,
        refs: null,
        init: function ($refs)  {
            this.refs = $refs;
        },
        initObservers: {
            ['@updateQuantity.window']: function ($event) {
                const data = $event.detail;
                if (!data) {
                    return;
                }
                this['qty'] = data.quantity;
            },
            ['@addProductToCart.window']: function ($event) {
                // @ts-ignore;
                this.addToCart($event, this.refs);
            },
            ['@private-data-loaded.window']: function ($event) {

                // @ts-ignore
                if (this.isAddToCartAction) {

                    // @ts-ignore
                    this.isAddToCartAction = false;
                }
            }
        },

        onQtyChange: function () {

            var event = new CustomEvent('updateQuantity', {
                detail: {
                    quantity: this.qty
                }
            });

            window.dispatchEvent(event);
        },

        incQuantity: function () {

            this.qty++;
            this.onQtyChange();
        },

        decQuantity: function () {

            if (this.qty > 1) {
                this.qty--;
                this.onQtyChange();
            }
        },

        addToCart: function ($event, $refs) {
            this.disabled = true;
            var event = new CustomEvent('updateAddToCartButtonState', {
                detail: {
                    disabled: this.disabled
                }
            });
            window.dispatchEvent(event);
            $event.preventDefault();
            var form = $refs.form;
            var url = form.getAttribute('action');

            fetch(url, {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                },
                body: new FormData(form)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        this.disabled = false;
                        var event = new CustomEvent('updateAddToCartButtonState', {
                            detail: {
                                disabled: this.disabled
                            }
                        });
                        window.dispatchEvent(event);
                        var status = data.status;
                        if (status == 1) {
                            // @ts-ignore
                            this.isAddToCartAction = true;
                            window.dispatchEvent(new CustomEvent("add-to-cart-successful", {}));
                            window.dispatchEvent(new CustomEvent("reload-private-data", {}));
                        } else {
                            window.dispatchEvent(new CustomEvent("messages-loaded", {
                                detail: {
                                    messages: [
                                        {
                                            'type': 'danger',
                                            // @ts-ignore
                                            'text': this.$el.getAttribute('data-addtocart-failed')
                                        }
                                    ]
                                }
                            }));
                        }
                    });
                }
            })
        }
    }
}
