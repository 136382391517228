export default function () {
    return {
        title: '',
        open: false,
        init: {
            ['@modal-open.window']: function($event) {
                this['open'] = true;
                this['title'] = $event.detail.title;
                this['$refs'].body.innerHTML = $event.detail.content.innerHTML;
            }
        },
        close: function () {
            this.open = false;
        }
    }
}
