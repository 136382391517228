export default function(){

    return {

      qty : 1,

      initObservers: {
        ['@updateQuantity.window']: function($event) {
          const data = $event.detail;
          if (!data) {
            return;
          }
          this['qty'] = data.quantity;
        }
      },
      onQtyChange: function() {

        var event = new CustomEvent('updateQuantity', {
          detail : {
            quantity : this.qty
          }
        });

        window.dispatchEvent(event);
      }
    }
}
