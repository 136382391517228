import Validation from "./validation";

class VarienForm {
    private form: any;
    private cache: any;
    private currLoader: boolean;
    private currDataIndex: boolean;
    private validator: Validation;
    private firstFieldFocus: boolean;
    private highlightClass: string;
    private elementFocus: OmitThisParameter<(event) => void>;
    private elementBlur: OmitThisParameter<(event) => void>;
    constructor(formId: string, firstFieldFocus) {
        this.form       = document.getElementById(formId);
        if (!this.form) {
            return;
        }
        this.cache      = [];
        this.currLoader = false;
        this.currDataIndex = false;
        this.validator  = new Validation(this.form);
        this.elementFocus   = this.elementOnFocus.bind(this);
        this.elementBlur    = this.elementOnBlur.bind(this);
        this.highlightClass = 'highlight';
        this.firstFieldFocus= firstFieldFocus || false;
        this.bindElements();
    }
    submit (url){
        if(this.validator && this.validator.validate()){
            this.form.submit();
        }
        return false;
    }

    bindElements (){
        var elements = this.form.elements;
        for (var row in elements) {
            if (!elements.hasOwnProperty(row)) {
                continue;
            }
            if (elements[row].id) {
                elements[row].addEventListener('focus', this.elementFocus);
                elements[row].addEventListener('blur', this.elementBlur);
            }
        }
    }

    elementOnFocus (event){

    }

    elementOnBlur (event){

    }
}

window['VarienForm'] = VarienForm;

export default VarienForm;
