
export default function getBrowserStorage(): Storage|null {
    const browserStorage = window.localStorage || window.sessionStorage;
    if (!browserStorage) {
        return null;
    }
    try {
        browserStorage.setItem('storage_test', '1');
        browserStorage.removeItem('storage_test');
    } catch (e) {
        return null;
    }
    return browserStorage;
}
