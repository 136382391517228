import { Swiper, Navigation, Lazy, Pagination, Autoplay} from 'swiper';
Swiper.use([Navigation, Lazy, Pagination, Autoplay]);

export default function(){

    return {
        initSlideStage: function($refs){
            const el = $refs.swiper;
            const prev = $refs.prev;
            const next = $refs.next;
            const pagination = $refs.pagination;
            
            let autoplay;
            let loop; //if autoplay true, enable loop

            if(el.getAttribute('data-autoplay') != 'false'){
                autoplay = { delay: el.getAttribute('data-autoplay') * 1000 };
                loop = true;
            } else {
                autoplay = false;
                loop = false;
            }

            const swiper = new Swiper(el, {
                loop: loop,
                slidesPerView: 1,
                spaceBetween: 0,
                preloadImages: false,
                autoplay: autoplay,
                lazy: {
                  loadPrevNext: true,
                },
                pagination: {
                  el: pagination,
                  clickable: true
                },
                navigation: {
                  nextEl: next,
                  prevEl: prev,
                }
            });            
            
        }
    }
}
