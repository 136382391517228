
export default function(){
    return {
        countryVal: '',
        data: {},
        regionsRequired: <any>[],
        loadData: function(url){
            fetch(url,{
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
                .then(function (response) {
                    return response.json()
                })
                .then(data => this.data = data)
        },
        setRegionsRequired: function(json){
            var region_data = json;
            var regionArray = Array();
            if ('config' in json && 'regions_required' in json['config']) {
                if (Array.isArray(json['config']['regions_required'])) {
                    for (let item = 0; item < json['config']['regions_required'].length; item++) {
                        regionArray.push(json['config']['regions_required'][item]);
                    }
                }
                this.regionsRequired = regionArray;
            }
        },
        onChange: function(event){
            this.countryVal = event.target && event.target.value;
        },
        get subRegions(){
            try {
                if (this.regionsRequired.includes(this.countryVal)) {
                    return Object.values(this.data['directory-data'][this.countryVal].regions);
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }
    }
}
