
import getBrowserStorage from '@frontend/utilities/storage';
import {getCookie} from '@frontend/utilities/cookie';

let privateData = {};
const privateContentKey = 'mage-cache-storage';
const privateContentExpireKey = 'mage-cache-timeout';
const privateContentVersionKey = 'private_content_version';
const ttl = 3600;
let url:string|null = null;

export function loadPrivateData() {
    const browserStorage = getBrowserStorage();
    if (!browserStorage) {
        // todo dispatch Message
        /*
        [{
                type: "warning",
                text: "Please enable LocalStorage in your browser."
            }]
         */
        return;
    }

        let cookieVersion = getCookie(privateContentVersionKey)
        if (!cookieVersion) {
            cookieVersion = "1";
        }

        const storageVersion = browserStorage.getItem(privateContentVersionKey);

        const privateContentExpires = browserStorage.getItem(privateContentExpireKey);
        if (privateContentExpires && new Date(privateContentExpires) < new Date()) {
            browserStorage.removeItem(privateContentKey);
        }

        if (cookieVersion && !storageVersion || cookieVersion !== storageVersion) {
            fetchPrivateContent([]);
            browserStorage.setItem(privateContentVersionKey, cookieVersion);
        } else if (cookieVersion && storageVersion && cookieVersion === storageVersion) {
            const json = browserStorage.getItem(privateContentKey);
            if (!json) {
                fetchPrivateContent([]);
                return;
            }
            const privateContent = JSON.parse(json);
            if (privateContent && privateContentExpires) {
                privateData = privateContent;
                dispatchPrivateDataEvent(privateData);
            } else {
                fetchPrivateContent([]);
            }
        }

}

function fetchPrivateContent(sections: string[] = []) {
    if (!url) {
        return;
    }
    fetch(url + '?ajax=1&timestamp='+ new Date().getTime() +'&sections=' + encodeURIComponent(
        sections.join(',')
    ), {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    }).then(function (response) {
        return response.json()
    }).then(function (data) {
        if (data) {
            try {
                const browserStorage = getBrowserStorage();
                if (!browserStorage) {
                    throw new Error();
                }
                browserStorage.setItem(privateContentKey, JSON.stringify(data));
                browserStorage.setItem(privateContentExpireKey, new Date(Date.now() + (ttl * 1000)).toISOString());
            } catch (error) {
                console.warn("Couldn't store privateContent", error);
            }
            privateData = data;
            dispatchPrivateDataEvent(privateData);
        }
    });
}

function dispatchPrivateDataEvent(data) {

    // @ts-ignore
    if(data.cart) window.cartData = data.cart;

    const privateContentEvent = new CustomEvent("private-data-loaded", {
        detail: {
            data: data
        }
    });
    window.dispatchEvent(privateContentEvent);
}

export function initPrivateData() {
    if (window['PRIVATE_DATA_URL']) {
        url = window['PRIVATE_DATA_URL'];
        loadPrivateData();
        window.addEventListener('load', loadPrivateData);
        window.addEventListener('reload-private-data', loadPrivateData);
    } else {
        window.addEventListener('load', function () {
            if (!window['PRIVATE_DATA_URL']) {
                console.log('No Private Data URL supplied...')
            } else {
                url = window['PRIVATE_DATA_URL'];
                loadPrivateData();
                window.addEventListener('load', loadPrivateData);
                window.addEventListener('reload-private-data', loadPrivateData);
            }
        });
    }
    window.addEventListener('init_delayed_scripts', () => {
        fetchPrivateContent();
    });

}
