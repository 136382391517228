import { Swiper, Navigation, Lazy} from 'swiper';
Swiper.use([Navigation, Lazy]);

export default function(data){

    return {
        initSlideCategories: function($refs){
            let el = $refs.swiper;
            let next = $refs.next;
            let prev = $refs.prev;

            let autoplay;
            let loop; //if autoplay true, enable loop

            if(el.getAttribute('data-autoplay') != 'false'){
                autoplay = { delay: el.getAttribute('data-autoplay') * 1000 };
                loop = true;
            } else {
                autoplay = false;
                loop = false;
            }

            debugger;

            const swiper = new Swiper(el, {
                loop: loop,
                slidesPerView: 2,
                spaceBetween: 20,
                preloadImages: false,
                autoplay: autoplay,
                lazy: {
                    loadPrevNext: true,
                },
                breakpoints: {
                    480: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 4
                    },
                    1280: {
                        slidesPerView: 4
                    }
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                }
            });

        }
    }
}

