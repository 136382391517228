export default function () {
    return {
        init: {
            ['@private-data-loaded.window']: function($event) {
                const data = $event.detail.data;
                if (!data.cart) {
                    return;
                }
                this['isLoggedIn'] = !!data.customer.fullname;
                this['customer'] = data.customer;
            }
        },
        isLoggedIn: false,
        customer: {
            fullname: null
        },
        showAccountMenu: false
    }
}
