export default function () {
  return {
      query: "",
      searchresultlists: null,
      initOnSiteSearch: function ($refs) {

        console.log('initOnSiteSearch', $refs.searchresultlists);

        this.searchresultlists = $refs.searchresultlists;
      },
      searchFn: function () {
        const input = this.query.toLowerCase();
        console.log('INPUT ', input.length);

        if (input.length == 0) {
          // @ts-ignore;
          for (const li of this.searchresultlists.children) {
            li.classList.remove('d-none');
            for(const lili of li.children){
              lili.classList.remove('d-none');
            }
          }
          return false;
        }
        
        if (input.length < 1) {
          return;
        } 
        // @ts-ignore;
        for (let li of this.searchresultlists.children) {          
          for(let lili of li.children){
            
            if(lili.attributes['data-search-term'].nodeValue.indexOf(input) >= 0){
              console.log('TRUE',lili.attributes['data-search-term'].nodeValue, input);
              lili.classList.remove('d-none');
              lili.classList.add('d-inline-block');
            } else {
              lili.classList.remove('d-inline-block');
              lili.classList.add('d-none');
            }
          }          
        }
        // @ts-ignore;
        for (const li of this.searchresultlists.children) {
          let visible = false;
          for(const lili of li.children){
            if(lili.classList.contains('d-inline-block')){
              visible = true
            } 
          }
          if(!visible){
            li.classList.add('d-none');
          } 
        }
      },
  }
}
