import MmenuLight from 'mmenu-light';

export default function (data: any) {
    return {
        initMenu: function () {
            const element = document.getElementById('mobileMenu');
            if (element) {
                const menu = new MmenuLight(element);
                // @ts-ignore
                const navigation = menu.navigation({
                    title: data.title
                });
                // @ts-ignore
                window.menuDrawer = menu.offcanvas({
                    position: "right"
                });
            }
        },
        openMenu: function () {
            // @ts-ignore
            if (window.menuDrawer) {
                let html = document.querySelector<HTMLElement>("html");
                html?.classList.add('overflow-hidden');
                // @ts-ignore
                window.menuDrawer.open();
            }
        },
        closeMenu: function () {
            // @ts-ignore
            if (window.menuDrawer) {
                let html = document.querySelector<HTMLElement>("html");
                html?.classList.remove('overflow-hidden');
                // @ts-ignore
                window.menuDrawer.close();
            }
        },
    }
}
