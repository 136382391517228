import Validation from "../validation";
function $w(string) {
    if (!(typeof string === 'string' || string instanceof String)) return [];
    string = string.trim();
    return string ? string.split(/\s+/) : [];
}

function parseNumber(v)
{
    if (typeof v != 'string') {
        return parseFloat(v);
    }

    var isDot  = v.indexOf('.');
    var isComa = v.indexOf(',');

    if (isDot != -1 && isComa != -1) {
        if (isComa > isDot) {
            v = v.replace('.', '').replace(',', '.');
        }
        else {
            v = v.replace(',', '');
        }
    }
    else if (isComa != -1) {
        v = v.replace(',', '.');
    }

    return parseFloat(v);
}

Validation.add('IsEmpty', '', function(v) {
    return  (v == '' || (v == null) || (v.length == 0) || /^\s+$/.test(v));
});


Validation.addAllThese([
    ['validate-no-html-tags', 'HTML tags are not allowed', function(v) {
        return !/<(\/)?\w+/.test(v);
    }],
    ['validate-select', 'Please select an option.', function(v) {
        return ((v != "none") && (v != null) && (v.length != 0));
    }],
    ['required-entry', 'This is a required field.', function(v) {
        return !Validation.get('IsEmpty').test(v);
    }],
    ['validate-number', 'Please enter a valid number in this field.', function(v) {
        return Validation.get('IsEmpty').test(v)
            || (!isNaN(parseNumber(v)) && /^\s*-?\d*(\.\d*)?\s*$/.test(v));
    }],
    ['validate-number-range', 'The value is not within the specified range.', function(v, elm) {
        if (Validation.get('IsEmpty').test(v)) {
            return true;
        }

        var numValue = parseNumber(v);
        if (isNaN(numValue)) {
            return false;
        }

        var reRange = /^number-range-(-?[\d.,]+)?-(-?[\d.,]+)?$/,
            result = true;

        $w(elm.className).each(function(name) {
            var m = reRange.exec(name);
            if (m) {
                result = result
                    && (m[1] == null || m[1] == '' || numValue >= parseNumber(m[1]))
                    && (m[2] == null || m[2] == '' || numValue <= parseNumber(m[2]));
            }
        });

        return result;
    }],
    ['validate-digits', 'Please use numbers only in this field. Please avoid spaces or other characters such as dots or commas.', function(v) {
        return Validation.get('IsEmpty').test(v) ||  !/[^\d]/.test(v);
    }],
    ['validate-digits-range', 'The value is not within the specified range.', function(v, elm) {
        if (Validation.get('IsEmpty').test(v)) {
            return true;
        }

        var numValue = parseNumber(v);
        if (isNaN(numValue)) {
            return false;
        }

        var reRange = /^digits-range-(-?\d+)?-(-?\d+)?$/,
            result = true;

        $w(elm.className).forEach(function(name) {
            var m = reRange.exec(name);
            if (m) {
                result = result
                    && (m[1] == null || m[1] == '' || numValue >= parseNumber(m[1]))
                    && (m[2] == null || m[2] == '' || numValue <= parseNumber(m[2]));
            }
        });

        return result;
    }],
    ['validate-alpha', 'Please use letters only (a-z or A-Z) in this field.', function (v) {
        return Validation.get('IsEmpty').test(v) ||  /^[a-zA-Z]+$/.test(v)
    }],
    ['validate-code', 'Please use only letters (a-z), numbers (0-9) or underscore(_) in this field, first character should be a letter.', function (v) {
        return Validation.get('IsEmpty').test(v) ||  /^[a-z]+[a-z0-9_]+$/.test(v)
    }],
    ['validate-code-event', 'Please do not use "event" for an attribute code.', function (v) {
        return Validation.get('IsEmpty').test(v) || !/^(event)$/.test(v)
    }],
    ['validate-alphanum', 'Please use only letters (a-z or A-Z) or numbers (0-9) only in this field. No spaces or other characters are allowed.', function(v) {
        return Validation.get('IsEmpty').test(v) || /^[a-zA-Z0-9]+$/.test(v)
    }],
    ['validate-alphanum-with-spaces', 'Please use only letters (a-z or A-Z), numbers (0-9) or spaces only in this field.', function(v) {
        return Validation.get('IsEmpty').test(v) || /^[a-zA-Z0-9 ]+$/.test(v)
    }],
    ['validate-street', 'Please use only letters (a-z or A-Z) or numbers (0-9) or spaces and # only in this field.', function(v) {
        return Validation.get('IsEmpty').test(v) ||  /^[ \w]{3,}([A-Za-z]\.)?([ \w]*\#\d+)?(\r\n| )[ \w]{3,}/.test(v)
    }],
    ['validate-phoneStrict', 'Please enter a valid phone number. For example (123) 456-7890 or 123-456-7890.', function(v) {
        return Validation.get('IsEmpty').test(v) || /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/.test(v);
    }],
    ['validate-phoneLax', 'Please enter a valid phone number. For example (123) 456-7890 or 123-456-7890.', function(v) {
        return Validation.get('IsEmpty').test(v) || /^((\d[-. ]?)?((\(\d{3}\))|\d{3}))?[-. ]?\d{3}[-. ]?\d{4}$/.test(v);
    }],
    ['validate-fax', 'Please enter a valid fax number. For example (123) 456-7890 or 123-456-7890.', function(v) {
        return Validation.get('IsEmpty').test(v) || /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/.test(v);
    }],
    ['validate-date', 'Please enter a valid date.', function(v) {
        var test = new Date(v);
        return Validation.get('IsEmpty').test(v) || !isNaN(test.getTime());
    }],
    ['validate-email', 'Please enter a valid email address. For example johndoe@domain.com.', function (v) {
        //return Validation.get('IsEmpty').test(v) || /\w{1,}[@][\w\-]{1,}([.]([\w\-]{1,})){1,3}$/.test(v)
        //return Validation.get('IsEmpty').test(v) || /^[\!\#$%\*/?|\^\{\}`~&\'\+\-=_a-z0-9][\!\#$%\*/?|\^\{\}`~&\'\+\-=_a-z0-9\.]{1,30}[\!\#$%\*/?|\^\{\}`~&\'\+\-=_a-z0-9]@([a-z0-9_-]{1,30}\.){1,5}[a-z]{2,4}$/i.test(v)
        return Validation.get('IsEmpty').test(v) || /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i.test(v)
    }],
    ['validate-emailSender', 'Please use only visible characters and spaces.', function (v) {
        return Validation.get('IsEmpty').test(v) ||  /^[\S ]+$/.test(v)
    }],
    ['validate-password', 'Please enter more characters or clean leading or trailing spaces.', function(v, elm) {
        var pass=v.strip(); /*strip leading and trailing spaces*/
        var reMin = new RegExp(/^min-pass-length-[0-9]+$/);
        var minLength = 7;
        $w(elm.className).each(function(name, index) {
            if (name.match(reMin)) {
                minLength = name.split('-')[3];
            }
        });
        return (!(v.length > 0 && v.length < minLength) && v.length == pass.length);
    }],
    ['validate-admin-password', 'Please enter more characters. Password should contain both numeric and alphabetic characters.', function(v, elm) {
        var pass=v.strip();
        if (0 == pass.length) {
            return true;
        }
        if (!(/[a-z]/i.test(v)) || !(/[0-9]/.test(v))) {
            return false;
        }
        var reMin = new RegExp(/^min-admin-pass-length-[0-9]+$/);
        var minLength = 7;
        $w(elm.className).each(function(name, index) {
            if (name.match(reMin)) {
                minLength = name.split('-')[4];
            }
        });
        return !(pass.length < minLength);
    }],
    ['validate-url', 'Please enter a valid URL. Protocol is required (http://, https:// or ftp://)', function (v) {
        v = (v || '').replace(/^\s+/, '').replace(/\s+$/, '');
        return Validation.get('IsEmpty').test(v) || /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(v)
    }],
    ['validate-clean-url', 'Please enter a valid URL. For example http://www.example.com or www.example.com', function (v) {
        return Validation.get('IsEmpty').test(v) || /^(http|https|ftp):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+.(com|org|net|dk|at|us|tv|info|uk|co.uk|biz|se)$)(:(\d+))?\/?/i.test(v) || /^(www)((\.[A-Z0-9][A-Z0-9_-]*)+.(com|org|net|dk|at|us|tv|info|uk|co.uk|biz|se)$)(:(\d+))?\/?/i.test(v)
    }],
    ['validate-identifier', 'Please enter a valid URL Key. For example "example-page", "example-page.html" or "anotherlevel/example-page".', function (v) {
        return Validation.get('IsEmpty').test(v) || /^[a-z0-9][a-z0-9_\/-]+(\.[a-z0-9_-]+)?$/.test(v)
    }],
    ['validate-xml-identifier', 'Please enter a valid XML-identifier. For example something_1, block5, id-4.', function (v) {
        return Validation.get('IsEmpty').test(v) || /^[A-Z][A-Z0-9_\/-]*$/i.test(v)
    }],
    ['validate-ssn', 'Please enter a valid social security number. For example 123-45-6789.', function(v) {
        return Validation.get('IsEmpty').test(v) || /^\d{3}-?\d{2}-?\d{4}$/.test(v);
    }],
    ['validate-zip', 'Please enter a valid zip code. For example 90602 or 90602-1234.', function(v) {
        return Validation.get('IsEmpty').test(v) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v);
    }],
    ['validate-zip-international', 'Please enter a valid zip code.', function(v) {
        //return Validation.get('IsEmpty').test(v) || /(^[A-z0-9]{2,10}([\s]{0,1}|[\-]{0,1})[A-z0-9]{2,10}$)/.test(v);
        return true;
    }],
    ['validate-date-au', 'Please use this date format: dd/mm/yyyy. For example 17/03/2006 for the 17th of March, 2006.', function(v) {
        if(Validation.get('IsEmpty').test(v)) return true;
        var regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if(!regex.test(v)) return false;
        var d = new Date(v.replace(regex, '$2/$1/$3'));
        return ( parseInt(RegExp.$2, 10) == (1+d.getMonth()) ) &&
            (parseInt(RegExp.$1, 10) == d.getDate()) &&
            (parseInt(RegExp.$3, 10) == d.getFullYear() );
    }],
    ['validate-currency-dollar', 'Please enter a valid $ amount. For example $100.00.', function(v) {
        // [$]1[##][,###]+[.##]
        // [$]1###+[.##]
        // [$]0.##
        // [$].##
        return Validation.get('IsEmpty').test(v) ||  /^\$?\-?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}\d*(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/.test(v)
    }],
    ['validate-one-required', 'Please select one of the above options.', function (v,elm) {
        var p = elm.parentNode;
        var options = p.getElementsByTagName('INPUT');
        return options.some(function(elm) {
            return !!elm.value;
        });
    }],
    ['validate-one-required-by-name', 'Please select one of the options.', function (v,elm) {
        var inputs = document.querySelectorAll('input[name="' + elm.name.replace(/([\\"])/g, '\\$1') + '"]');

        var error = 1;
        for(var i=0;i<inputs.length;i++) {
            const input: HTMLInputElement = inputs[i] as HTMLInputElement;
            if((input.type == 'checkbox' || input.type == 'radio') && input.checked) {
                error = 0;
            }
        }

        if( error == 0 ) {
            return true;
        } else {
            return false;
        }
    }],
    ['validate-not-negative-number', 'Please enter a number 0 or greater in this field.', function(v) {
        if (Validation.get('IsEmpty').test(v)) {
            return true;
        }
        v = parseNumber(v);
        return !isNaN(v) && v >= 0;
    }],
    ['validate-zero-or-greater', 'Please enter a number 0 or greater in this field.', function(v) {
        return Validation.get('validate-not-negative-number').test(v);
    }],
    ['validate-greater-than-zero', 'Please enter a number greater than 0 in this field.', function(v) {
        if (Validation.get('IsEmpty').test(v)) {
            return true;
        }
        v = parseNumber(v);
        return !isNaN(v) && v > 0;
    }],
    ['validate-state', 'Please select State/Province.', function(v) {
        return (v!=0 || v == '');
    }],
    ['validate-new-password', 'Please enter more characters or clean leading or trailing spaces.', function(v, elm) {
        if (!Validation.get('validate-password').test(v, elm)) return false;
        if (Validation.get('IsEmpty').test(v) && v != '') return false;
        return true;
    }],
    ['validate-data', 'Please use only letters (a-z or A-Z), numbers (0-9) or underscore(_) in this field, first character should be a letter.', function (v) {
        if(v != '' && v) {
            return /^[A-Za-z]+[A-Za-z0-9_]+$/.test(v);
        }
        return true;
    }],
    ['validate-css-length', 'Please input a valid CSS-length. For example 100px or 77pt or 20em or .5ex or 50%.', function (v) {
        if (v != '' && v) {
            return /^[0-9\.]+(px|pt|em|ex|%)?$/.test(v) && (!(/\..*\./.test(v))) && !(/\.$/.test(v));
        }
        return true;
    }],
    ['validate-length', 'Text length does not satisfy specified text range.', function (v, elm) {
        var reMax = new RegExp(/^maximum-length-[0-9]+$/);
        var reMin = new RegExp(/^minimum-length-[0-9]+$/);
        var result = true;
        $w(elm.className).forEach(function(name, index) {
            if (name.match(reMax) && result) {
                const length = name.split('-')[2];
                result = (v.length <= length);
            }
            if (name.match(reMin) && result && !Validation.get('IsEmpty').test(v)) {
                const length = name.split('-')[2];
                result = (v.length >= length);
            }
        });
        return result;
    }],
    ['validate-percents', 'Please enter a number lower than 100.', {max:100}]
]);
