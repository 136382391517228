import menuAim from "@frontend/utilities/menu-aim";
import lazyload_instance from "../utilities/lazyload";

export default function () {
    return {
        initMenu: function ($refs) {
            const element = $refs.megaDropDown;
            if (element) {
              let isActiveElement;
              menuAim(element, {
                    activate:  function(row: HTMLElement){
                        isActiveElement = row.querySelector('.nav-link--level-1.active');
                        const link = row.querySelector('.nav-link--level-1');
                        if (link) {
                            link.classList.add('active');
                        }
                        const menu = row.querySelector('.megadropdown');
                        if (menu) {
                            menu.classList.add('active');
                            lazyload_instance.update();
                        }
                    },
                    deactivate: function(row: HTMLElement){
                        const link = row.querySelector('.nav-link--level-1');
                        if (link && link != isActiveElement) {
                            link.classList.remove('active');
                        }
                        const menu = row.querySelector('.megadropdown');
                        if (menu) {
                            menu.classList.remove('active');
                        }
                    },
                    exitMenu: function () {
                        return true;
                    },
                    rowSelector: ":scope > div",
                    submenuDirection: "below",
                    activationDelay: 100
                });
            }
        }
    }
}
