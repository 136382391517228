import GLightbox from 'glightbox';

export default function () {
  return {
    lightboxElement: null,
    lightbox: undefined,
    init: function($refs) {
        this.lightboxElement = $refs.lightbox;
    },
    close: function ()  {
        if (this.lightbox) {
            // @ts-ignore
            this.lightbox.close();
        }
    },
    initObservers: {
      ['@add-to-cart-successful.window']: function ($event) {
        // @ts-ignore
        this.lightbox = new GLightbox({
            elements: [{
                // @ts-ignore
                content: this.lightboxElement
            }],
            closeButton: false
        });
        // @ts-ignore
        this.lightbox.open();
        if (window['lazyLoadInstance']) {
            window['lazyLoadInstance'].update();
        }
      }

    }
  }
}
