export default function () {
    return {

        blockDefault: null,
        customBlocks: null,

        init: function (default_id) {
            // @ts-ignore
            this.blockDefault = document.getElementById('js-megamenu-teaser-default-' + default_id);
            // @ts-ignore
            this.customBlocks = document.getElementsByClassName('js-megamenu-teaser');
        },

        updateBlock: function (id, parent_id) {

            var customBlock = document.getElementById('js-megamenu-teaser-' + id);
            var customBlockParent = document.getElementById('js-megamenu-teaser-' + parent_id);

            // @ts-ignore
            for (var i = 0; i < this.customBlocks.length; i++) {
                // @ts-ignore
                this.customBlocks[i].style.display = 'none';
            }

            // @ts-ignore
            this.blockDefault.style.display = "none";

            if(customBlock !== null) {
                // @ts-ignore
                customBlock.style.display = "block";
            }
            else {

                if(customBlockParent !== null) {
                    // @ts-ignore
                    customBlockParent.style.display = "block";
                }
                else {
                    // @ts-ignore
                    this.blockDefault.style.display = "block";
                }
            }
        }
    }
}
